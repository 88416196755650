import React from "react";
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../../components/layout";

const BreadcrumbModule = loadable(() => import("../../components/BreadcrumbModule/BreadcrumbModule"));
const ContactIntro = loadable(() => import("../../components/ContactIntro/ContactIntro"));
const ContactDetail = loadable(() => import("../../components/ContactDetail/ContactDetail"));
const InstantValuationForm = loadable(() => import("../../components/ContactForm/InstantValuationForm"));

const InstantValuation = (props) => {
    const { wpPage:data} = props.data;
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule />

            <div className="contact-page-wrapper section-p">
                <Container>
                    <Row>
                        <Col xl={4}>
                            <ContactIntro 
                                tag="instant-valuation"
                            />

                            <ContactDetail />
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={7}>
                            <InstantValuationForm />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}


export const query = graphql`
  query {
    wpPage(databaseId: {eq: 1549}) {
        id
        title
        ACF_Cards {
            card {
                ctaDescription
                ctaLabel
                ctaTitle
                ctaLink {
                ... on WpPage {
                    id
                    slug
                }
                }
                ctaImage {
                id
                caption
                sourceUrl
                }
            }
        }
    }
  }  
`

export default InstantValuation